<template>
  <!-- unknown block - make sure a component exists to support it -->
</template>

<script>
export default {
  data: function() {
    return {};
  }

};
</script>

<style scoped lang="scss">

</style>
